import React from "react";
import NavBar from "../components/navbar";

const Speech=()=>{
    React.useEffect(() => {
        fetch("https://fingerspell-api.onrender.com/api/speech")
          .then((res) => res.json())
          .then((data) => {
             
          });
      }, []);
      return (<div style={{backgroundColor:"black"}}>
          <NavBar/>
      </div>)
}
export default Speech;