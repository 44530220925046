import React from "react";
import '../components/css/login.css'
import NavBar from "../components/navbar";
import { Redirect } from 'react-router-dom'


class Register extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
		  username : '',
		  password: '',
		  email:'',
		  redirect:false
		};
	  }
	  handleInputChange = (event) => {
		const { value, name } = event.target;
		this.setState({
		  [name]: value
		});
	  }
	 

	  renderRedirect = () => {
		  console.log(this.state.redirect);
		if (this.state.redirect==true) {
			console.log("here2");
		  return <Redirect to='/login' />
		}
	  }
	

	render(){
		const onSubmit = (event) => {
			event.preventDefault();
			const { username, email,password } = this.state;
			const data= {username, email,password}
			//console.log(JSON.stringify(data))
		
		   fetch('https://fingerspell-api.onrender.com/api/register', { 
			   method:'POST',
			   
			   headers:{'Accept': 'application/json',"Content-Type":"application/json"},
			   body:JSON.stringify(data)
			   }).then(response => response.json())
			   .then(json => {
			
				console.log("here");
				this.setState({redirect:true});   
				})
			   .catch(error => {
				   console.log('Authorization failed : ' + error.message)
			   });
			  
		  }
		 
		
    return(
      <div className="login">
		  <NavBar/>
		 { this.renderRedirect()}
            <div className="container fadeInDown">
	  <div id="formContent">
	
	    <h2 className="active"> Sign In </h2>
	    <h2 className="inactive underlineHover">Sign Up</h2>
	  
	    <form onSubmit={onSubmit}>
	      <input type="text" className="fadeIn second" placeholder="Username" name="username"  onChange={this.handleInputChange}/>
		  <input type="text" className="fadeIn second" placeholder="Email" name="email"  onChange={this.handleInputChange}/>
	      <input type="password" className="fadeIn third" placeholder="Password" name="password"  onChange={this.handleInputChange}/>
	      <input type="submit" className="fadeIn fourth" value="Register"/>
	    </form>   
	  
	    <div id="formFooter">
	      <a className="underlineHover" href="#">Forgot Password?</a>
	    </div>
	  </div>
	</div>
	</div>
    
    );
	}
}
export default Register