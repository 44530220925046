import React, { useState } from "react";
import '../components/css/post.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useParams} from "react-router-dom";
import { Exp } from "@tensorflow/tfjs-core";
import { Redirect } from 'react-router-dom'
import NavBar from "../components/navbar";
const Explore =()=>{
    const {spaceid, postid}=useParams();
    const [post,setPost]= useState(null);
    const [reload, setReload]= useState(false);
  //  console.log(id);
    React.useEffect(() => {
      fetch("https://fingerspell-api.onrender.com/api/getpost/"+postid)
        .then((res) => res.json())
        .then((data) => {
            setPost(data.post)
        });
    }, []);

    const [comment, setComment]=useState(null);
    const handleInputChange = (event) => {
       const { value} = event.target;
       setComment(value);
     }
    const onSubmit = (event) => {
		event.preventDefault();

		const data= {comment}
		console.log(JSON.stringify(data))
    
       fetch(`https://fingerspell-api.onrender.com/api/${postid}/addcomment`, { 
		   method:'POST',
		   headers:{'Accept': 'application/json',"Content-Type":"application/json"},
		   body:JSON.stringify(data),
		   credentials: 'include'
		   })   .then(response => response.json())
		   .then(json =>{
          
             console.log(json)
           }
           )
		   .catch(error => console.log('Authorization failed : ' + error.message));

           fetch("/getpost/"+postid)
           .then((res) => res.json())
           .then((data) => {
               setPost(data.post)
           });
          
	  }
    
    console.log(post)
    return(
        <div className="post" style={{paddingLeft:"10%", paddingRight:"10%"}}>
          <NavBar/>
            <div className="row nextRow">
             
                    <div className="space-posts">
                    <form onSubmit={onSubmit}>
                 
	            <textarea className="fadeIn second" placeholder="Add Comment" name="question"  onChange={handleInputChange}/>
	            <input type="submit" className="fadeIn fourth" value="Add Comment"/>
	    </form>   
                    
                        {!post?"loading":
                        post.comments.map((comment)=>{
                       return( <div className="single-post">
                          
                            <div className="card">
                                <div className="card-header">
                                   Featured
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title" style={{color:"black"}}>{comment.commentor}</h5>
                                    <p className="card-text">{comment.content}</p>
                                 
                                </div>
                            </div>

                      
                    </div>);
                    })
                    }
                    </div>
                    

                </div>
       

            </div>
     
    )

}
export default Explore;